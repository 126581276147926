import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map} from 'rxjs';
import {getPaths} from './preset-helper';
import {SfoUiJSONSchema7, ExampleTypes, Example} from '../../metadata.model';
import {MenuItem, MenuExample} from './menu-item.model';

/**
 * Component representing a menu of examples for a given schema.
 */
@Component({
  selector: 'sfo-preset-menu',
  templateUrl: './preset-menu.component.html',
  styleUrls: ['./preset-menu.component.scss'],
})
export class PresetMenuComponent implements OnInit, OnDestroy {
  /**
   * The control name.
   */
  @Input() propertyName: string;

  /**
   * The schema containing metadata for the examples.
   */
  @Input() schema: SfoUiJSONSchema7 | undefined;

  /**
   * The form control or form group associated with the schema.
   */
  @Input() aFormControl: FormGroup | FormControl;

  menuItems: MenuItem[];
  initialMessage: string = 'Presets';
  presetMessage: string = '';

  private timeoutDuration: number = 3000;
  private timeoutExpiry;
  private timeoutInterval;

  ngOnInit() {
    if (!this.schema) {
      return;
    }

    const results = this.traverseSchema(this.schema, this.propertyName);

    this.menuItems = results;

    if (!this.aFormControl) {
      throw Error('No form has been supplied.');
    }

    this.aFormControl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        map((values) => {
          const flatPaths = getPaths(values, this.propertyName);
          this.toggleActiveExamples(this.menuItems, flatPaths);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    clearInterval(this.timeoutInterval);
  }

  isRootMenu(path: string): boolean {
    return path.indexOf('.') === -1;
  }

  getLastKey(path: string): string {
    const parts = path.split('.');
    return parts[parts.length - 1];
  }

  applyExample(item: MenuItem, example: ExampleTypes) {
    let message = '';

    if (typeof example === 'object') {
      const updatingObject = {} as Example;

      Object.keys(example).forEach((fieldKey) => {
        if (fieldKey !== '_comment') {
          updatingObject[fieldKey] = example[fieldKey];
        }
      });
    }

    const pathSegments: string[] = item.path.split('.');
    pathSegments.shift();

    this.applyExampleRecursively(example, pathSegments, this.aFormControl);

    message = example['_comment'] || example.toString();
    this.setPresetMessage(message);
  }

  setPresetMessage(message) {
    this.presetMessage = `Active: ${message}`;

    this.timeoutExpiry = new Date(Date.now() + this.timeoutDuration);

    if (this.timeoutInterval) {
      clearInterval(this.timeoutInterval);
    }

    // Set up a new interval to clear the message once the timeout expires
    this.timeoutInterval = setInterval(() => {
      const now = new Date();
      if (now >= this.timeoutExpiry) {
        this.presetMessage = ''; // Clear the message once the timeout expires
        clearInterval(this.timeoutInterval); // Clear the interval
      }
    }, 1000); // Check every second (adjust the interval as needed)
  }

  toggleActiveExamples(menuItems: MenuItem[], paths: {[key: string]: string | number | boolean}[]) {
    if (!paths.length) {
      return;
    }

    const rootExamples: MenuExample[] | undefined = menuItems.find(
      (item) => item.path === this.propertyName,
    )?.examples;

    if (rootExamples && rootExamples.length > 0) {
      this.toggleRootExample(rootExamples, paths);
    }

    this.toggleFlattenPathExample(menuItems, paths);
  }

  toggleRootExample(
    rootExamples: MenuExample[],
    paths: {[key: string]: number | string | boolean}[],
  ) {
    const filterRootPaths: {[key: string]: number | string | boolean}[] = paths.map(
      (pathObject) => {
        const currentKey = Object.keys(pathObject)[0];
        const [prefix, key] = currentKey.split('.');
        if (prefix === this.propertyName) {
          return {[key]: pathObject[currentKey]};
        }
        return {};
      },
    );

    rootExamples.forEach((example) => {
      let isActive = true;

      if (typeof example.data === 'object') {
        isActive = this.isPatchValueMatching(example.data, filterRootPaths);
      } else {
        isActive = example.data === filterRootPaths[0]?.value;
      }

      example.isActive = isActive;
    });
  }

  toggleFlattenPathExample(menuItems, paths) {
    menuItems.forEach((menuItem) => {
      if (menuItem && !menuItem.examples.length) {
        return; // no examples in this menuItem so return
      }
      const relatedPath = paths.find((path) =>
        Object.prototype.hasOwnProperty.call(path, menuItem.path),
      );
      if (!relatedPath) {
        return; // no matching path to menuItem
      }
      const currentExample: MenuExample[] = menuItem.examples;
      currentExample.forEach((example) => {
        let isActive = true;
        if (typeof example.data === 'object') {
          isActive = this.isPatchValueMatching(example.data, paths);
        } else {
          isActive = example.data === relatedPath[menuItem.path];
        }
        example.isActive = isActive;
      });
    });
  }

  private isPatchValueMatching(
    exampleData: Example,
    paths: {[key: string]: number | string | boolean}[],
  ): boolean {
    if (!paths) {
      return false;
    }

    for (const [key, value] of Object.entries(exampleData)) {
      if (key !== '_comment') {
        const path = paths.find((path) => Object.prototype.hasOwnProperty.call(path, key));
        if (path !== undefined && path !== null && path[key] !== value) {
          return false;
        }
      }
    }
    return true;
  }

  private applyExampleRecursively(
    example: ExampleTypes,
    pathSegments: string[] | undefined,
    formControl: FormControl | FormGroup,
  ) {
    if (pathSegments && pathSegments.length > 0 && formControl) {
      const newFormControl = formControl?.get(pathSegments[0]) as FormGroup | FormControl;
      const newPathSegments = pathSegments.slice(1);
      this.applyExampleRecursively(example, newPathSegments, newFormControl); // Pass remaining segments
    } else {
      // Last segment reached, update the form control
      const newFormControl: FormControl = formControl as FormControl;
      newFormControl.patchValue(example);
    }
  }

  private traverseSchema(schema: SfoUiJSONSchema7, propertyName: string = ''): MenuItem[] {
    const result: MenuItem[] = [];

    if (schema.examples && schema.examples.length > 0) {
      const nestedMenu: MenuItem = {
        path: propertyName,
        examples: schema.examples.map((example: ExampleTypes) => ({
          data: example,
          isActive: false,
        })),
      };
      result.push(nestedMenu);
    }

    if (!schema.properties) {
      return result;
    }

    for (const [key, value] of Object.entries(schema.properties)) {
      const nestedPropertyName = propertyName ? `${propertyName}.${key}` : key;

      if (value.examples && value.examples.length > 0) {
        const nestedMenu: MenuItem = {
          path: nestedPropertyName,
          examples: value.examples.map((example: ExampleTypes) => ({
            data: example,
            isActive: false,
          })),
        };
        result.push(nestedMenu);
      } else if (value.properties) {
        const nestedMenus = this.traverseSchema(value, nestedPropertyName);
        result.push(...nestedMenus);
      }
    }

    return result;
  }
}
