import { Plugin, PluginKey, EditorState, TextSelection, NodeSelection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { dropPoint } from 'prosemirror-transform';
import { insertCitationCommand } from './commands';
import { addCitations } from '../store/references.actions';
import { CitationItem, CitationWithId, SourceField } from '@sciflow/cite';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { selectCitationlist } from '../store/references.reducer';

const extractCitations = (doc): CitationWithId[] => {
    const citations: CitationWithId[] = [];
    doc.nodesBetween(0, doc.nodeSize - 2, (node) => {
        if (node.type.name === 'citation') {
            const source = SourceField.fromString(node.attrs.source);
            const citationItems: CitationItem[] = source.citationItems;
            citations.push({
                citationID: node.attrs.id,
                plainCitation: node.textContent,
                citationItems: [...citationItems],
                properties: {
                    noteIndex: 0
                }
            });
        }
    });
    return citations;
}

const handleDrop = (view, event, slice, moved) => {
    const node = slice.content.firstChild;
    console.log(slice);

    if (!moved && node.type.name === 'citation') {
        const eventPos = view.posAtCoords({ left: event.clientX, top: event.clientY });
        if (!eventPos) { return false; }
        const mousePos = view.state.doc.resolve(eventPos.pos);

        const tr = view.state.tr;
        const insertPos = dropPoint(view.state.doc, mousePos.pos, slice);
        if (insertPos == null) { return false; }
        view.dispatch(tr.setSelection(TextSelection.create(tr.doc, insertPos)));
        insertCitationCommand(node.attrs.source, node.textContent)(view.state, view.dispatch);
        return true;
    }
    return false;
};

export const referencePluginKey = new PluginKey('references');

export const getReferencePlugin = (store: Store, getEditorView: (editorView: EditorView) => void) => {
    return new Plugin<{ citations: any[], pristine: boolean; }>({
        key: referencePluginKey,
        state: {
            init(_config, state: EditorState) { return { citations: extractCitations(state.doc), pristine: false }; },
            apply(transaction, oldState: any, newState: EditorState) {
                if (!transaction.docChanged) { return oldState; }
                const citations = extractCitations(transaction.doc);
                store.dispatch(addCitations({ citations }));
                return { ...oldState, citations, pristine: false };
            },
        },
        view: (editorView: EditorView) => {
            // TODO initialize state if needed
            getEditorView(editorView);
            return {
                // called after every editor update
                update: async (_view, state) => {
                    const pluginScope = referencePluginKey.get(state);
                    const pluginState = pluginScope?.getState(state)
                    // update citations in the store if they have changed
                    if (!pluginState?.pristine) {
                        const citations = await store.select(selectCitationlist).pipe(take(1)).toPromise();
                        if (citations?.length === 0) { store.dispatch(addCitations({ citations: pluginState.citations })); }
                    }
                },
                destroy: () => {
                    console.log('destroy');
                }
            }
        },
        props: {
            handleDrop
        }
    });
}