<mat-drawer-container class="col-12" style="height: 100%;" autosize>
  <mat-drawer
    #drawer
    mode="side"
    opened
    disableClose
    style="margin: auto"
    class="mat-drawer-background"
    aria-label="Settings Navigation"
  >
    <div class="flex flex-column" style="height: 100%;">
      <!-- TODO: Search Functionality  -->
      <!-- <div class="flex justify-center">
        <mat-form-field class="search-input">
          <mat-icon matPrefix>search</mat-icon>
          <input matInput [formControl]="searchControl" placeholder="Search settings" />
          @if (searchControl.value) {
            <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
              <mat-icon>clear</mat-icon>
            </button>
          }
        </mat-form-field>
      </div> -->
      <mat-selection-list
        #matSelectionList
        [multiple]="false"
        [hideSingleSelectionIndicator]="true"
        class="mx2"
        color="primary"
        aria-label="Settings Options"
      >
        <mat-list-option
          *ngFor="let tab of filteredTabs; trackBy: trackByLabel"
          role="navigation"
          (click)="selectConfiguration(tab.id)"
          [matTooltip]="tab.description || ''"
          [matTooltipPosition]="'after'"
        >
          <mat-icon matListItemIcon *ngIf="tab.icon" aria-hidden="true">{{tab.icon}}</mat-icon>
          <mat-icon matListItemIcon *ngIf="!tab.icon" aria-hidden="true">arrow_right</mat-icon>
          <div matListItemTitle>{{ tab.label }}</div>
        </mat-list-option>
      </mat-selection-list>
    </div>
  </mat-drawer>

  <mat-drawer-content>
    <div class="dca-scrollarea" data-test="drawer-container">
      <ng-template #vcr data-test="vcr"></ng-template>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
