import { Component, OnInit, Input, Output, ChangeDetectionStrategy, EventEmitter, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import {MatDialog } from '@angular/material/dialog';
import { AffiliationDialogComponent } from '../affiliation-dialog/affiliation-dialog.component';
import { FunderDialogComponent } from '../funder-dialog/funder-dialog.component';
import { Store } from '@ngrx/store';
import { AuthorInUI, selectAuthor } from '../author-management.reducer';
import { deleteAuthor, setSelectedAuthor, markUnsaved, markSaved, clearAuthorData, toggleHideInPublication, makeGroupContribution, addAuthor, createAuthor, updateAuthor } from '../author-management.actions';
import { Subject, BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray, Validators, AbstractControl } from '@angular/forms';
import { OrcidService } from './orcid.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { GroupDialogComponent } from '../group-dialog/group-dialog.component';
import { selectAuthors } from 'projects/reference-app/src/app/app-state.reducer';
import { Router, ActivatedRoute } from '@angular/router';
import { updateDocumentState } from 'editor';
import { MatSnackBar } from '@angular/material/snack-bar';

export const createId = (): string => 'abcdefghijklmnopqrstuvwxyz'.charAt(Math.floor(Math.random() * 26)) + Math.random().toString(36).substr(2, 9);

/** Hashes a string */
export const hash = (json: any): string => {
  const s = JSON.stringify(json);
  var hash = 0, i, chr;
  if (s.length === 0) { return `${hash}`; }
  for (i = 0; i < s.length; i++) {
      chr = s.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
  }

  return hash
  .toString()
  .replace('-', '9')
  .split('')
  .map(char => Number.parseInt(char) + 65)
  .map(char => String.fromCharCode(char))
  .join('')
  .toLowerCase();
};


@Component({
  selector: 'sf-author-edit',
  templateUrl: './author-edit.component.html',
  styleUrls: ['./author-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorEditComponent {

  @ViewChild('orcidInput', { static: false }) orcidField;
  @Output() update = new EventEmitter();
  @Output() remove = new EventEmitter();

  authorForm = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    type: new UntypedFormControl('author'),
    rank: new UntypedFormControl(0),
    editable: new UntypedFormControl(false),
    userId: new UntypedFormControl(''),
    authorId: new UntypedFormControl(''),
    invitedAt: new UntypedFormControl(''),
    currentUser: new UntypedFormControl(false),
    allowedRoles: new UntypedFormArray([new UntypedFormControl()]),
    roles: new UntypedFormControl(['Author']),
    title: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    firstName: new UntypedFormControl(''),
    lastName: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    twitter: new UntypedFormControl(''),
    orcid: new UntypedFormControl('', [
      Validators.pattern('[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}')
    ]),
    equalContribution: new UntypedFormControl(''),
    correspondingAuthor: new UntypedFormControl(''),
    deceased: new UntypedFormControl(''),
    positions: new UntypedFormArray([]),
    groups: new UntypedFormArray([new UntypedFormControl()]),
    funders: new UntypedFormArray([]),
    hideInPublication: new UntypedFormControl(false)
  });

  @Input() set authorId(authorId: string) {
    this.authorId$.next(authorId);
  }

  /** Whether the person being edited is an author (e.g. part of a document) */
  @Input() isAuthor: boolean;

  @Output() closed: EventEmitter<any> = new EventEmitter();

  isLoading$ = new Subject();

  stop$ = new Subject<void>();
  authorId$ = new BehaviorSubject('');

  author$ = this.authorId$.pipe(
    takeUntil(this.stop$),
    distinctUntilChanged(),
    switchMap((authorId) => {
      return this.store.select(selectAuthor, { authorId }).pipe(map((author) => {
        if (author) { return author; }
        return createAuthor(createId());
      }));
    })
  );

  groups$ = this.store.select(selectAuthors).pipe(map((authors) => authors.filter(author => author.type === 'group')), startWith([] as AuthorInUI[]));

  notAUser$ = this.author$.pipe(map(author => !author.userId));
  emailVerified$ = this.author$.pipe(map(author => author.emailVerified === true));

  editUser = true;

  constructor(public dialog: MatDialog, private store: Store, private orcidService: OrcidService, private cdr: ChangeDetectorRef, private router: Router,
              private activatedRoute: ActivatedRoute, private snackbar: MatSnackBar) { }

  //
  // Affiliations
  //
  createPosition(): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(null),
      title: new UntypedFormControl(''),
      department: new UntypedFormControl(''),
      institution: new UntypedFormControl(''),
      street: new UntypedFormControl(''),
      city: new UntypedFormControl(''),
      country: new UntypedFormControl(''),
      phone: new UntypedFormControl(''),
      primary: new UntypedFormControl(false)
    });
  }

  get groups() {
    return this.authorForm.get('groups') as UntypedFormArray;
  }

  get positions() {
    return this.authorForm.get('positions') as UntypedFormArray;
  }

  get type() {
    return this.authorForm.get('type') as UntypedFormControl;
  }

  showName(author) {
    if (author.type === 'group') { return true; }
    if (author?.name?.length > 0) { return true; }
    return false;
  }

  hideInPublication(authorId, hide = false) {
    this.store.dispatch(toggleHideInPublication({ authorId, hide }));
    this.authorForm.get('hideInPublication')?.patchValue(hide);
    this.save({ clearData: false, updateProfile: false });
  }

  makeGroupContribution(authorId) {
    this.store.dispatch(makeGroupContribution({ authorId }));
    this.authorForm.get('type')?.patchValue('group');
    this.save({ clearData: false, updateProfile: false });
  }

  openAffiliationDialog(affiliation?) {

    const copy = this.createPosition();
    if (affiliation) { copy.patchValue(affiliation.value); }

    const dialogRef = this.dialog.open(AffiliationDialogComponent, {
      minWidth: '40vw',
      data: {
        affiliation: copy
      }
    });

    dialogRef.afterClosed().subscribe(updatedAffiliation => {
      if (updatedAffiliation) {
        let replace = false;
        if (!updatedAffiliation.id) {
          updatedAffiliation.id = hash(updatedAffiliation);
        }
        for (const [index, value] of this.positions.getRawValue().entries()) {
          if (value.id === updatedAffiliation.id) {
            this.positions.at(index).patchValue(updatedAffiliation);
            replace = true;
          }
        }

        if (!replace) {
          copy.patchValue(updatedAffiliation);
          this.positions.push(copy);
        }

        // we need to trigger updates ourselves since cdr has no chance to detect the changes
        this.authorForm.markAsDirty();
        this.store.dispatch(markUnsaved());
        this.cdr.detectChanges();
      }
    });
  }


  drop(event: CdkDragDrop<string[]>) {
    if (event.previousIndex === event.currentIndex) { return; }

    const item = this.positions.at(event.previousIndex);
    this.positions.removeAt(event.previousIndex);
    this.positions.insert(event.currentIndex, item);
    this.authorForm.markAsDirty();
    this.store.dispatch(markUnsaved());
  }

  deleteAffiliation(affiliation) {
    this.positions.removeAt(this.positions.controls.findIndex(af => af.get('id')?.value === affiliation.get('id').value));
    this.authorForm.markAsDirty();
    this.store.dispatch(markUnsaved());
  }

  async getOrcid() {
    this.isLoading$.next(true);
    try {
      const record = await this.orcidService.getOrcid(this.authorForm.get('orcid')?.value);
      this.updateFromOrcid(record);
    } catch (e: any) {
      this.authorForm.get('orcid')?.setErrors({ serverError: e.message });
    } finally {
      this.isLoading$.next(false);
    }
  }

  updateFromOrcid(recordUpdated) {
    this.authorForm.patchValue(recordUpdated.author);
    let overwrite = false;
    if (this.positions.length > 0 && (recordUpdated.affiliations?.length > 0 || this.funders.length > 0 && recordUpdated.funders?.length > 0)) {
      overwrite = confirm('Should existing affiliations and funders be overwritten?');
    }
    if (overwrite) {
      this.positions.clear();
      this.funders.clear();
      recordUpdated.affiliations.forEach((affiliation) => {
        const p = this.createPosition();
        p.setValue(affiliation);
        this.positions.push(p);
      });
      recordUpdated.funders.forEach((funder) => {
        const f = this.createFunder();
        f.setValue(funder);
        this.funders.push(f);
      });
    } else {
      recordUpdated.affiliations.forEach((affiliation) => {
        const p = this.createPosition();
        p.patchValue(affiliation);
        this.positions.push(p);
      });
      recordUpdated.funders.forEach((funder) => {
        const f = this.createFunder();
        f.patchValue(funder);
        this.funders.push(f);
      });
    }

    this.authorForm.markAsDirty();
    this.store.dispatch(markUnsaved());
    this.cdr.detectChanges();
  }

  createFunder(): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(null),
      countryCode: new UntypedFormControl(null),
      institution: new UntypedFormGroup({
        id: new UntypedFormControl(null),
        name: new UntypedFormControl(null),
        type: new UntypedFormControl('doi'),
      }),
      awardIds: new UntypedFormControl()
    });
  }

  get funders() {
    return this.authorForm.get('funders') as UntypedFormArray;
  }

  openFunderDialog(funder?: AbstractControl) {
    const copy = this.createFunder();
    if (funder) { copy.patchValue(funder.value); }

    const dialogRef = this.dialog.open(FunderDialogComponent, {
      minWidth: '40vw',
      data: {
        funder: copy
      }
    });

    dialogRef.afterClosed().subscribe(updatedFunder => {
      if (updatedFunder) {
        let replace = false;
        updatedFunder.awardIds = (Array.isArray(updatedFunder.awardIds)) ? updatedFunder.awardIds : updatedFunder.awardIds ? [updatedFunder.awardIds] : null;
        if (!updatedFunder.id) {
          updatedFunder.id = hash(updatedFunder);
        }
        for (const [index, value] of this.funders.getRawValue().entries()) {
          if (value.id === updatedFunder.id) {
            this.funders.at(index).patchValue(updatedFunder);
            replace = true;
          }
        }

        if (!replace) {
          copy.patchValue(updatedFunder);
          this.funders.push(copy);
        }

        // we need to trigger updates ourselves since cdr has no chance to detect the changes
        this.authorForm.markAsDirty();
        this.store.dispatch(markUnsaved());
        this.cdr.detectChanges();
      }
    });
  }

  openGroupDialog() {

    const dialogRef = this.dialog.open(GroupDialogComponent, {
      minWidth: '40vw'
    });

    dialogRef.afterClosed().subscribe(group => {
      if (group) {
        const groupId = createId();
        const author = {
          ...createAuthor(groupId),
          ...group,
          roles: ['Author'],
          type: 'group',
        };

        this.store.dispatch(updateAuthor({ author }));
        this.groups.patchValue([groupId]);

        // we need to trigger updates ourselves since cdr has no chance to detect the changes
        this.authorForm.markAsDirty();
        this.store.dispatch(markUnsaved());
        this.cdr.detectChanges();
      }
    });
  }

  deleteFunder(funder) {
    this.funders.removeAt(this.funders.controls.findIndex(af => af.get('id')?.value === funder.get('id').value));
    this.authorForm.markAsDirty();
    this.store.dispatch(markUnsaved());
  }

  dropFunders(event: CdkDragDrop<string[]>) {
    if (event.previousIndex === event.currentIndex) { return; }

    const item = this.funders.at(event.previousIndex);
    this.funders.removeAt(event.previousIndex);
    this.funders.insert(event.currentIndex, item);
    this.authorForm.markAsDirty();
    this.store.dispatch(markUnsaved());
  }

  close() {
    this.store.dispatch(setSelectedAuthor({ authorId: null }));
    this.closed.emit(null);
    this.router.navigate(['../../'], { relativeTo: this.activatedRoute });
  }

  save({ clearData, updateProfile }: { clearData?: boolean; updateProfile?: boolean; }) {
    const author = this.authorForm.value;
    author.groups = author.groups.filter(v => v != null);

    this.update.emit({ author, clearData, updateProfile });
    this.authorForm.markAsPristine();
    this.store.dispatch(markSaved());
    this.store.dispatch(updateDocumentState({ dirty: true }));
    this.snackbar.open('Saved author successfully', 'Close', { duration: 5000 });
  }

  deleteAuthor(authorId: string) {
    this.store.dispatch(setSelectedAuthor({ authorId: null }));
    this.store.dispatch(deleteAuthor({ authorId }));
    this.store.dispatch(updateDocumentState({ dirty: true }));
  }

  clearAuthorData(authorId: string) {
    const rank = this.authorForm.get('rank')?.value;
    const userId = this.authorForm.get('userId')?.value;
    this.authorForm.reset();
    this.authorForm.patchValue(createAuthor(authorId, rank, userId));
    this.store.dispatch(clearAuthorData({ authorId }));
    this.save({ clearData: true });
  }

  ngOnInit(): void {
    this.author$.pipe(
      filter(a => a != null),
      debounceTime(200),
      switchMap((author) => {
        this.authorForm.reset();
        this.authorForm.patchValue(author);
        this.positions.clear();

        author.positions.forEach((a) => {
          const p = this.createPosition();
          p.patchValue(a);
          this.positions.push(p);
        });

        this.funders.clear();
        author.funders.forEach((f) => {
          const funder = this.createFunder();
          funder.patchValue(f);
          this.funders.push(funder);
        });

        if (!author.editable) {
          this.authorForm.disable();
        } else {
          this.authorForm.enable();
        }

        // email is never enabled
        this.authorForm.get('email')?.disable();

        this.authorForm.markAsPristine();
        this.store.dispatch(markSaved());

        this.authorForm.statusChanges.pipe(
          // make sure we stop listening when the component is destroyed or a different author is selected (in case the form never gets enabled)
          takeUntil(this.stop$),
          // don't react to disabled forms
          filter(status => status !== 'DISABLED'),
          // delay by 200ms
          debounceTime(200),
          // only do this once
          take(1)).subscribe((status) => {
            if (this.authorForm.pristine && !this.authorForm.touched) {
              this.orcidField?.nativeElement?.focus();
            }
          });

        return this.authorForm.statusChanges.pipe(takeUntil(this.stop$)).pipe(
          map((status) => {
            if (!this.authorForm.pristine) {
              this.store.dispatch(markUnsaved());
              this.store.dispatch(updateDocumentState({ dirty: true }));
            } else {
              this.store.dispatch(markSaved());
            }
          }));
      }),
      takeUntil(this.stop$)).subscribe(() => {
        //
      });

    this.authorForm.updateValueAndValidity();
  }

  ngOnDestroy() {
    this.stop$.next();
  }

}
