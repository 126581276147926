import {DOCUMENT} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {updateDocumentState} from 'editor';
import {Subject, firstValueFrom} from 'rxjs';
import {debounceTime, take} from 'rxjs/operators';
import {
  checkSelectedAuthor,
  selectActiveEditor,
  selectDocumentModified,
  selectDocumentTitle,
  selectIsDirty,
} from '../app/app-state.reducer';

import {ProjectService} from '../editor/shared/services/project.service';
import {selectIsDocumentLoaded, selectOpenFile} from './file.reducer';

// TODO windowRef should be central
import {minimizeExportSettings} from 'export';
import {WindowRef} from '../../../author-management/src/lib/WindowRef';
import {AuthService} from '../../sciflow/auth.service';
import {IntercomService} from '../../sciflow/intercom.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnDestroy {
  @ViewChild('copyToClipboardInput') copyToClipboardInput: ElementRef;

  @HostListener('keydown', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    if ((e.ctrlKey || e.metaKey) && e.key === 's') {
      e.stopPropagation();
      e.preventDefault();
      this.save();
    }
  }

  stop$ = new Subject<void>();

  isDark = false;
  dcaExpanded$ = this.store.select(checkSelectedAuthor);
  documentTitle$ = this.store.select(selectDocumentTitle);
  lastModified$ = this.store.select(selectDocumentModified);
  isDocumentLoaded$ = this.store.select(selectIsDocumentLoaded);

  openFile$ = this.store.select(selectOpenFile);

  instanceData = JSON.parse(this.document.getElementById('instance-data').innerHTML);

  myControl = new UntypedFormControl();

  themes: any[] = [
    {
      full: 'Use device theme',
      short: 'ST',
    },
    {
      full: 'Dark theme',
      short: 'DT',
    },
    {
      full: 'Light theme',
      short: 'LT',
    },
  ];
  selectedTheme = 'ST';

  selectedThemeControl = new UntypedFormControl(this.selectedTheme);

  constructor(
    private cd: ChangeDetectorRef,
    private store: Store,
    private router: Router,
    private snackBar: MatSnackBar,
    private projectService: ProjectService,
    private windowRef: WindowRef,
    @Inject(DOCUMENT) private document: any,
    private authService: AuthService,
    private intercomService: IntercomService,
  ) {
    this.checkdefaultAppmode();
    this.addwatchthemePrefers();

    this.intercomService.init();

    this.router.errorHandler = (error: any) => {
      console.error('Fatal error', error);
      this.router.navigate(['/500']);
    };
  }

  /**
   * Copies the current url to the clipboard.
   */
  copyToClipboard(): void {
    // this.copyToClipboardInput.nativeElement.value = window.location.href;
    // this.copyToClipboardInput.nativeElement.select();

    // document.execCommand('copy');

    const textToCopy = window.location.href;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        this.snackBar.open(
          `We have copied the link to this document to your clipboard`,
          undefined,
          {duration: 5000},
        );
      })
      .catch((error) => {
        this.snackBar.open(`Copy failed! ${error}`, undefined, {duration: 5000});
      });
  }

  checkdefaultAppmode(): void {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      if (this.selectedTheme === 'DT') {
        this.isDark = true;
      }
      if (this.selectedTheme === 'LT') {
        this.isDark = false;
      } else {
        this.isDark = true;
      }
    }
  }

  addwatchthemePrefers(): void {
    // To watch for changes:
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      const newColorScheme = e.matches ? 'dark' : 'light';
      if (this.selectedTheme === 'ST') {
        if (newColorScheme === 'dark') {
          this.isDark = true;
        } else {
          this.isDark = false;
        }
      }
      this.cd.markForCheck();
    });
  }

  selectTheme(value): void {
    this.selectedTheme = value;
    if (value === 'DT') {
      this.isDark = true;
    }
    if (value === 'LT') {
      this.isDark = false;
    }
    if (value === 'ST') {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.isDark = true;
      } else {
        this.isDark = false;
      }
    }
  }

  ngOnDestroy(): void {
    window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', (e) => {
      this.cd.detach();
    });
    this.stop$.next();
  }

  /**
   * Exports the active snapshot to SciFlow if configured with an app url.
   */
  async openInSciFlow() {
    if (!this.instanceData.sciflowAppDomain) {
      this.snackBar.open('This instance is not configured to push to SciFlow', undefined, {
        duration: 3000,
      });
      return;
    }

    this.snackBar.open('Fowarding snapshot of latest saved document...', undefined, {
      duration: 2000,
    });
    const editor = await firstValueFrom(this.store.select(selectActiveEditor));
    if (editor?.key) {
      const snapshotUrl = `${this.windowRef.origin}/export/snapshot/${editor.key}.zip`;
      this.windowRef.open(
        `https://${this.instanceData.sciflowAppDomain}/document/i/pull/${encodeURIComponent(snapshotUrl)}`,
        '_blank',
      );
    } else {
      this.snackBar.open('No active document found to export. Consider saving again.', undefined, {
        duration: 5000,
      });
    }

    //this.router.navigateByUrl(this.instanceData.sciflowAppDomain + )
  }

  async save(openExport = true) {
    // wait a few ms for events to settle that happened before save
    const isDirty = await this.store
      .select(selectIsDirty)
      .pipe(debounceTime(250), take(1))
      .toPromise();

    if (isDirty) {
      this.snackBar.open('Saving ...');
      const persistedStore: any = await this.projectService.persistStore();

      if (!persistedStore) {
        console.error(persistedStore);
        this.snackBar.open('Could not process file', 'Close', {duration: 5000});
      } else {
        console.log('Updated successfully', persistedStore.result);
        this.snackBar.open('Saved successfully', 'Close', {duration: 5000});
        this.store.dispatch(updateDocumentState({dirty: false}));
        this.store.dispatch(minimizeExportSettings());
        // Comment code below as export settings does not use routing
        // This could change if UI library switches to URL
        // let exportContext = '';
        // if (openExport) {
        //   exportContext = `/(context:export/${persistedStore.data?.manuscript?.template || 'generic-journal'})`;
        // }

        // const url = `/edit/${persistedStore.result.key}/${persistedStore.result.version?.VersionId || 'unversioned-' + createId()}${exportContext}`;
        // this.router.navigateByUrl(url);
        return;
      }
    }

    const file: any = await this.openFile$.pipe(take(1)).toPromise();
    const url = `/edit/${file.projectId}/${file.id}.json/${file.version}/(context:export/generic-journal)`;
    this.router.navigateByUrl(url);
  }
}
