import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MatSelectionList} from '@angular/material/list';
import {BehaviorSubject} from 'rxjs';
import {SettingsViewEditorComponent} from './settings-editor-view/settings-editor-view.component';
import {SfoUiJSONSchema7} from '../../metadata.model';
import {FormService} from '../form.service';

interface Tab {
  /** Unique id */
  id: string;
  /** Human readable label */
  label: string;
  description?: string;
  /** A material icon name  */
  icon?: string;
  /**  A tag like meta */
  tag?: string;
}

@Component({
  selector: 'settings-tab-view',
  templateUrl: './settings-view-tab.component.html',
  styleUrls: ['./settings-view-tab.component.scss'],
})
export class SettingsViewComponent implements OnInit, OnDestroy {
  private SettingsViewEditorComponent = SettingsViewEditorComponent;

  formService = inject(FormService);

  schema$: BehaviorSubject<SfoUiJSONSchema7 | null> = new BehaviorSubject<SfoUiJSONSchema7 | null>(
    null,
  );
  activeTab: string = '';
  cdr = inject(ChangeDetectorRef);
  advancedMode$ = this.formService.advancedMode$;

  @ViewChild('vcr', {static: true, read: ViewContainerRef}) vcr!: ViewContainerRef;
  @ViewChild('matSelectionList') matSelectionList: MatSelectionList;
  @Input() aFormControl: FormGroup;
  @Input() set schema(data) {
    this.schema$.next(data);
  }

  tabs: Tab[] = [];
  filteredTabs: Tab[] = [];
  // searchControl: FormControl = new FormControl('');

  ngOnInit(): void {
    this.schema$.subscribe((data) => {
      this.reset();
      this.tabs = [];
      this.activeTab = '';

      if (this.matSelectionList) {
        this.matSelectionList.deselectAll();
      }

      if (!data || !data.properties) {
        return;
      }

      for (const [key, value] of Object.entries(data.properties)) {
        this.tabs.push({
          label: value?.title ?? key,
          icon: value['_ui_icon'],
          id: key,
          tag: value['_ui_tag'],
          description: value?.description,
        });
      }

      this.filteredTabs = this.tabs;
      this.renderDynamicComponent('');

      // Setup search control with debounce
      // this.searchControl.valueChanges
      //   .pipe(debounceTime(500))
      //   .subscribe((query) => this.filterTabs(query));
    });
  }

  ngOnDestroy(): void {
    this.reset();
  }

  selectConfiguration(control: string) {
    if (this.activeTab === control) {
      return;
    }

    this.activeTab = control;
    this.renderDynamicComponent(control);
  }

  renderDynamicComponent(control: string) {
    this.reset();

    const schema: SfoUiJSONSchema7 | null = this.schema$.getValue();

    const component: ComponentRef<SettingsViewEditorComponent> = this.vcr.createComponent(
      SettingsViewEditorComponent,
    );

    component.instance.schema = schema;
    component.instance.aFormControl = this.aFormControl;
    component.instance.control = control;

    this.cdr.detectChanges();
  }

  private reset() {
    if (this.vcr) {
      this.vcr.clear();
    }
  }

  filterTabs(query: string) {
    const lowerCaseQuery = query.toLowerCase();
    this.filteredTabs = this.tabs.filter((tab) =>
      this.containsQuery(this.schema$.getValue()?.properties?.[tab.label], lowerCaseQuery),
    );
  }

  containsQuery(node: any, query: string): boolean {
    if (!node) return false;

    if (typeof node === 'string') {
      return node.toLowerCase().includes(query.toLowerCase());
    }

    if (Array.isArray(node)) {
      return node.some((child) => this.containsQuery(child, query));
    }

    if (typeof node === 'object') {
      return Object.keys(node).some((key) => this.containsQuery(node[key], query));
    }

    return false;
  }

  trackByLabel(index: number, tab: {label: string; icon?: string}) {
    return tab.label;
  }

  toggleAdvanceMode() {
    this.formService.toggleAdvancedMode();
  }

  // clearSearch() {
  //   this.searchControl.setValue('');
  // }
}
