<div class="dca-heading flex items-center justify-between px2" aria-label="outline">
  <div class="h2 flex items-center">
    <span>Document outline</span>
  </div>
  <div class="flex items-center">
    <button mat-icon-button matTooltip="Learn more">
      <mat-icon>help_outline</mat-icon>
    </button>
  </div>
</div>
<div class="dca-scrollarea">
  <mat-form-field appearance="fill" class="select-type-field">
    <mat-label>Select Content Type</mat-label>
    <mat-select [(value)]="selectedTypes" #select multiple>
      <div class="select-all">
        <mat-checkbox [(ngModel)]="allSelected" (change)="toggleAllSelection()">
          Select All</mat-checkbox>
      </div>
      <mat-option *ngFor="let item of contentTypes" [value]="item">{{item}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="info-container">
    <header>
      <h2>Structure your manuscript</h2>
    </header>
    <p>
      The document outline shows all chapters/parts of the document. Always make sure to mark chapter titles as Heading 1.
    </p>
  </div>
  <div *ngIf="headings$ | async; let headings">
    <mat-list class="disable-select">
      <mat-list-item class="list-item" *ngFor="let heading of headings | filterByType:selectedTypes">
        <div data-id="{{heading.id}}" class="heading level-{{heading.level}} {{heading.type}}" draggable="true"
          matTooltip="drag elements into document" (click)="headingClick(heading)"
          (dragstart)="dragStart($event, heading)" (dragend)="dragEnd($event)">
          <div class="draggable"></div>
          <span class="heading-level-box" *ngIf="heading.type === 'heading'">H{{heading.level}}</span>
          <span class="heading-level-box uppercase" *ngIf="heading.type !== 'heading'">{{heading.type}}
            {{heading.pos}}</span>
          <span class="heading-text-box" *ngIf="heading.label && heading.label.length > 0"> {{heading.label}}</span>
          <span class="heading-text-box" *ngIf="!heading.label || heading.label.length === 0"> ... </span>
        </div>
        <!-- <mat-icon *ngIf="heading.type === 'figure'">image</mat-icon>
        <mat-icon *ngIf="heading.type === 'code'">code</mat-icon>
        <mat-icon *ngIf="heading.type === 'Equation'">functions</mat-icon>
        <mat-icon *ngIf="heading.type === 'table'">table_chart</mat-icon> -->
      </mat-list-item>
    </mat-list>
  </div>
  <div class="info-container">
    <header>
      <h2>Add more chapters (h1)</h2>
    </header>
    <p>Headings you add to the document will show up here.</p>
    <p><span>To add any other headings h1-h5 you can select any text and click the </span>
      <mat-icon>short_text</mat-icon><span> symbol left to the paragraph.</span>
    </p>
    <header>
      <h2>Cross referencing</h2>
    </header>
    <p>To reference a heading or figure, just drag it into the document.</p>
  </div>
</div>