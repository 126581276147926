import { Affiliations } from "./instruct-affiliations";
import { Authors } from "./instruct-authors";
import { Keywords } from "./instruct-keywords";
import { Parts } from "./instruct-parts";
import { References } from "./instruct-references";
import { Title } from "./instruct-title";

export * from './instruct-title';
export * from './instruct-parts';
export * from './instruct-affiliations';
export * from './instruct-references';
export * from './instruct-keywords';

export interface AnnotatedManuscriptResponse {
    /** The parsed manuscript */
    data?: AnnotatedManuscript;
    /** A list of messages and warnings that occured while reading the manuscript */
    logs?: string[];
}

export interface AnnotatedManuscript extends Title, Parts, Authors, Affiliations, Keywords, References {}

export type CSL = any;
